/** @format */

import {colors} from './colors';
import {fonts} from './fonts';
import {textStyles} from './textStyles';

export const Button = {
	// 1. Update the base styles
	baseStyle: {
		borderRadius: '0rem',
		fontFamily: fonts.button,
		fontWeight: '400',
		fontSize: '16px',
		// paddingTop: "12px",
		// paddingBottom: "12px",
		// paddingRight: "24px",
		// paddingLeft: "24px",
		// transition: 'all 0.4s ease-in-out', // already default in chakra
		// lineHeight: '24px',
	},
	sizes: {
		lg: {
			fontSize: '16px',
			// h: '3em',
			// fontSize: 'sm',
			// px: '22px',
			// py: '12px',
		},
	},
	// https://chakra-ui.com/docs/styled-system/customize-theme#customizing-single-components
	defaultProps: {
		// variant: 'solid',
		// size: 'md',
		// colorScheme: 'flag',
	},
	variants: {
		solid(props: any) {
			const {colorScheme: c} = props;
			if (c === 'pink' || c === 'greyWarm') return {color: 'black'};
			if (c === 'yellow')
				return {
					bg: `${c}.500`,
					_hover: {
						bg: `${c}.600`,
						_disabled: {
							textDecoration: 'none',
						},
					},
					_active: {
						bg: `${c}.700`,
					},
				};
		},
		outline(props: any) {
			const {colorScheme: c} = props;
			const objToReturn = {
				border: '2px solid',
			};

			if (
				c === 'gold' ||
				c === 'pine' ||
				c === 'pink' ||
				c === 'maroon' ||
				c === 'aqua' ||
				c === 'yellow' ||
				c === 'green'
			) {
				return {
					border: '2px solid',
					borderColor: `${c}.500`,
					color: `${c}.500`,
					_hover: {
						_disabled: {
							textDecoration: 'none',
						},
						bg: `${c}.100`,
						borderColor: `${c}.600`,
						color: `${c}.600`,
					},
					_active: {
						bg: `${c}.200`,
						borderColor: `${c}.700`,
						color: `${c}.700`,
					},
				};
			}

			if (c === 'greyWarm') {
				return {
					border: '2px solid',
					borderColor: `${c}.500`,
					color: `${c}.500`,
					_hover: {
						_disabled: {
							textDecoration: 'none',
						},
						bg: 'blackAlpha.blackAlpha_400',
						borderColor: `${c}.500`,
						color: `${c}.500`,
					},
					_active: {
						bg: 'blackAlpha.blackAlpha_500',
						borderColor: `${c}.500`,
						color: `${c}.500`,
					},
				};
			}

			// if (c === 'blackAlpha') {
			// }

			return objToReturn;
		},
		ghost(props) {
			const {colorScheme: c} = props;
			return {
				fontFamily: fonts.copernicusGalaxyMedium,
				// _hover: {
				// 	_disabled: {
				// 		textDecoration: 'none',
				// 	},
				// 	// bg: `${c}.100`,
				// 	// borderColor: `${c}.600`,
				// 	// color: `${c}.600`,
				// },
				// _active: {
				// 	bg: `${c}.200`,
				// 	borderColor: `${c}.700`,
				// 	color: `${c}.700`,
				// },
			};
		},
		link(props) {
			const {colorScheme: c} = props;
			return {
				position: 'relative',
				fontFamily: fonts.copernicusGalaxyMedium,
				textWrap: 'wrap',
				transition: 'all 0.4s ease-in-out', // already default in chakra
				'::before': {
					content: '""',
					position: 'absolute',
					width: '100%',
					height: '2px',
					borderRadius: '2px',
					backgroundColor: colors.white.whiteAlpha,
					bottom: '0',
					left: '0',
					transformOrigin: 'left',
					transform: 'scaleX(0)',
					transition: 'all .2s ease-in-out',
				},
				_hover: {
					textDecoration: 'none',
					'::before': {
						transformOrigin: 'left',
						transform: 'scaleX(1)',
					},
				},
				// _active: {
				// 	bg: `${c}.200`,
				// 	borderColor: `${c}.700`,
				// 	color: `${c}.700`,
				// },
			};
		},
	},
};
