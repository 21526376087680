/** @format */

import {fonts} from './fonts';

import {defineStyle, defineStyleConfig} from '@chakra-ui/react';
import {textStyles} from './textStyles';

export const Badge = {
	// 1. Update the base styles
	baseStyle: {
		...textStyles.textxs,
		...{
			textTransform: 'none',
			fontSize: '12px',
			padding: '4px 8px',
		},
	},
	sizes: {
		md: {
			// h: '3em',
			// fontSize: 'sm',
			// px: '22px',
			// py: '12px',
		},
	},
	defaultProps: {
		// variant: 'solid',
		// size: 'md',
		// colorScheme: 'flag',
	},
	variants: {
		// This cannot be done as chkra ui do not allo using colorScheme.100, it only accept a string like 'green' or 'blue'
		//
		// solid(props) {
		// 	const {colorScheme} = props;
		// 	if (colorScheme)
		// 		return {
		// 			bg: colorScheme,
		// 		};
		// },
		// outline(props) {
		// 	const {colorScheme} = props;
		// 	if (colorScheme)
		// 		return {
		// 			// bg: colorScheme,
		// 		};
		// },
		// subtle(props) {
		// 	const {colorScheme} = props;
		// 	if (colorScheme)
		// 		return {
		// 			bg: colorScheme,
		// 		};
		// },
		custom(props) {
			const {colorScheme} = props;
			if (colorScheme)
				return {
					bg: colorScheme,
				};
		},
	},
};
