/** @format */

import {AppProps} from 'next/app';
import '../styles/index.css';
import {ChakraProvider} from '@chakra-ui/react';
import {theme} from '../styles/js/theme';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React, {useEffect} from 'react';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {useRouter} from 'next/router';

function MyApp({Component, pageProps}: AppProps) {
	const AnyComponent = Component as any;
	const router = useRouter();
	const handleRouteChange = (url: string) => {
		if (window?.gtag) {
			window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_CODE, {
				page_path: url,
			});
		}
	};

	useEffect(() => {
		router.events.on('routeChangeComplete', handleRouteChange);
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, [router.events]);

	Bugsnag.start({
		apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
		plugins: [new BugsnagPluginReact()],
		enabledReleaseStages: ['production', 'staging'],
	});

	const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

	return (
		<ErrorBoundary>
			<ChakraProvider theme={theme}>
				<GoogleReCaptchaProvider
					reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
					scriptProps={{
						async: true,
						defer: true,
						appendTo: 'head',
						nonce: undefined,
					}}>
					<AnyComponent {...pageProps} />
				</GoogleReCaptchaProvider>
			</ChakraProvider>
		</ErrorBoundary>
	);
}

export default MyApp;
