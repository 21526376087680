/** @format */

import {Button} from './button';
import {colors} from './colors';

export const Link = {
	baseStyle: {
		// color: colors.aqua[500],
		textDecoration: 'underline',
		transition: 'all .2s ease-in-out',
		_hover: {
			textDecoration: 'underline',
			color: colors.aqua[500],
		},
	},
	sizes: Button.sizes,
};
