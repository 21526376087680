/** @format */

export const borderRadius = {
	radii: {
		0: '0rem',
		sm: '0.125rem',
		base: '0.25rem',
		md: '0.375rem',
		lg: '0.5rem',
		xl: '0.75rem',
		'2xl': '1rem',
		'3xl': '1.5rem',
		full: '9001px',
	},
};
