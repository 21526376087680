/** @format */

import { fonts } from "./fonts";
import { fontSizes } from "./typography";
import { colors } from "./colors";

// colllege + garden = display
// harlaxton+ events = heading
// paragraph style  = text

export const textStyles = {
    text7Xl: {
        // fontFamily: fonts.body,
        fontFamily: fonts.copernicusGalaxyBook,
        fontSize: fontSizes["6xl"],
        // fontSize: 96,
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "104px",
        letterSpacing: "-3px",
        // color: colors.black,
    },
    heading6Xl: {
        fontFamily: fonts.copernicusGalaxyBook,
        fontSize: "72px",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: "80px",
        letterSpacing: "-0.72px",
        // color: colors.black,
    },
    text6Xl: {
        fontFamily: fonts.copernicusGalaxyBook,
        fontSize: "72px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "80px",
        letterSpacing: "-0.72px",
        // color: colors.black,
    },
    display6Xl: {
        fontFamily: fonts.eDNimpkishRegular,
        fontSize: "72px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "80px",
        letterSpacing: "-0.72px",
        // color: colors.black,
    },
    heading5Xl: {
        fontFamily: fonts.copernicusGalaxyBook,
        fontSize: "64px",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: "72px",
        letterSpacing: "-0.64px",
        // color: colors.black,
    },
    text5Xl: {
        fontFamily: fonts.copernicusGalaxyBook,
        fontSize: "64px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "72px",
        letterSpacing: "-0.64px",
        // color: colors.black,
    },
    display5Xl: {
        fontFamily: fonts.eDNimpkishRegular,
        fontSize: "64px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "72px",
        letterSpacing: "-0.64px",
        // color: colors.black,
    },
    heading4Xl: {
        fontFamily: fonts.copernicusGalaxyBook,
        fontSize: "56px",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: "64px",
        letterSpacing: "-0.56px",
        // color: colors.black,
    },
    text4Xl: {
        fontFamily: fonts.copernicusGalaxyBook,
        fontSize: "56px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "64px",
        letterSpacing: "-0.56px",
        // color: colors.black,
    },
    display4Xl: {
        fontFamily: fonts.eDNimpkishRegular,
        fontSize: "56px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "64px",
        letterSpacing: "-0.56px",
        // color: colors.black,
    },
    heading3Xl: {
        fontFamily: fonts.copernicusGalaxyBook,
        fontSize: "48px",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: "56px",
        letterSpacing: "-0.48px",
        // color: colors.black,
    },
    text3Xl: {
        fontFamily: fonts.copernicusGalaxyBook,
        fontSize: "48px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "56px",
        letterSpacing: "-0.48px",
        // color: colors.black,
    },
    display3Xl: {
        fontFamily: fonts.eDNimpkishRegular,
        fontSize: "48px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "56px",
        letterSpacing: "-0.48px",
        // color: colors.black,
    },
    heading2Xl: {
        fontFamily: fonts.copernicusGalaxyBook,
        fontSize: "40px",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: "48px",
        letterSpacing: "-0.4px",
        // color: colors.black,
    },
    heading5XlGillSans: {
        fontFamily: fonts.GillSansBold,
        fontSize: "64px",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: "72px",
        letterSpacing: "-0.64px",
        // color: colors.black,
    },
    heading4XlGillSans: {
        fontFamily: fonts.GillSansBold,
        fontSize: "56px",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: "64px",
        letterSpacing: "-0.56px",
        // color: colors.black,
    },
    heading3XlGillSans: {
        fontFamily: fonts.GillSansBold,
        fontSize: "48px",
        fontWeight: 700,
        fontStyle: "normal",
        lineHeight: "56px",
        letterSpacing: "-0.48px",
        // color: colors.black,
    },
    heading2XlGillSans: {
        fontFamily: fonts.GillSansBold,
        fontSize: "40px",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: "48px",
        letterSpacing: "-0.4px",
        // color: colors.black,
    },
    headingxlGillSans: {
        fontFamily: fonts.GillSansBold,
        fontSize: "32px",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: "40px",
        letterSpacing: "-0.32px",
        // color: colors.black,
    },
    headinglgGillSans: {
        fontFamily: fonts.GillSansBold,
        fontSize: "24px",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: "36px",
        letterSpacing: "-0.24px",
        // color: colors.black,
    },
    textxlGillSans: {
        fontFamily: fonts.GillSansMedium,
        fontSize: "32px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "40px",
        letterSpacing: "-0.32px",
        // color: colors.black,
    },
    textsmGillSans: {
        fontFamily: fonts.GillSansMedium,
        fontSize: "16px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
        // color: colors.black,
    },
    text2Xl: {
        fontFamily: fonts.copernicusGalaxyBook,
        fontSize: "40px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "48px",
        letterSpacing: "-0.4px",
        // color: colors.black,
    },
    display2Xl: {
        fontFamily: fonts.eDNimpkishRegular,
        fontSize: "40px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "48px",
        letterSpacing: "-0.4px",
        // color: colors.black,
    },
    headingxl: {
        fontFamily: fonts.copernicusGalaxyBook,
        fontSize: "32px",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: "40px",
        letterSpacing: "-0.32px",
        // color: colors.black,
    },
    textxl: {
        fontFamily: fonts.copernicusGalaxyBook,
        fontSize: "32px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "40px",
        letterSpacing: "-0.32px",
        // color: colors.black,
    },
    displayxl: {
        fontFamily: fonts.eDNimpkishRegular,
        fontSize: "32px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "40px",
        letterSpacing: "-0.32px",
        // color: colors.black,
    },
    headinglg: {
        fontFamily: fonts.copernicusGalaxyBook,
        fontSize: "24px",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: "36px",
        letterSpacing: "-0.24px",
        // color: colors.black,
    },
    textlg: {
        fontFamily: fonts.copernicusGalaxyBook,
        fontSize: "24px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "36px",
        letterSpacing: "-0.24px",
        // color: colors.black,
    },
    displaylg: {
        fontFamily: fonts.eDNimpkishRegular,
        fontSize: "24px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "36px",
        letterSpacing: "-0.24px",
        // color: colors.black,
    },
    headingmd: {
        fontFamily: fonts.copernicusGalaxyBook,
        fontSize: "20px",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: "32px",
        letterSpacing: "-0.2px",
        // color: colors.black,
    },
    textmd: {
        fontFamily: fonts.copernicusGalaxyBook,
        fontSize: "20px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "32px",
        letterSpacing: "-0.2px",
        // color: colors.black,
    },
    displaymd: {
        fontFamily: fonts.eDNimpkishRegular,
        fontSize: "20px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "32px",
        letterSpacing: "-0.2px",
        // color: colors.black,
    },
    headingsm: {
        fontFamily: fonts.copernicusGalaxyBook,
        fontSize: "16px",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
        // color: colors.black,
    },
    headingsmGillSans: {
        fontFamily: fonts.GillSansMedium,
        fontSize: "18px",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
        // color: colors.black,
    },
    textsm: {
        fontFamily: fonts.copernicusGalaxyBook,
        fontSize: "16px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "24px",
        letterSpacing: "-0.16px",
        // color: colors.black,
    },
    displaysm: {
        fontFamily: fonts.eDNimpkishRegular,
        fontSize: "16px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "24px",
        letterSpacing: "0px",
        // color: colors.black,
    },
    headingxs: {
        fontFamily: fonts.copernicusGalaxyBook,
        fontSize: "12px",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: "16px",
        letterSpacing: "-0.12px",
        // color: colors.black,
    },
    textxs: {
        fontFamily: fonts.copernicusGalaxyBook,
        fontSize: "12px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "16px",
        letterSpacing: "-0.12px",
        // color: colors.black,
    },
    displayxs: {
        fontFamily: fonts.eDNimpkishRegular,
        fontSize: "12px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "16px",
        letterSpacing: "0px",
        // color: colors.black,
    },
    textxsGillSans: {
        fontFamily: fonts.GillSansBold,
        fontSize: "12px",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "16px",
        letterSpacing: "-0.12px",
        // color: colors.black,
    },
};
