/** @format */

import localFont from "next/font/local";
interface Fonts {
    // [key: string]: string;
    heading: string;
    body: string;
    tag: string;
    button: string;
    copernicusGalaxyMedium: string;
    copernicusGalaxyBook: string;
    eDNimpkishRegular: string;
    GillSansMedium: string;
    GillSansMediumItalic: string;
    GillSansBold: string;
}

const copernicusGalaxyMedium = localFont({
    src: [
        {
            path: "../../public/fonts/galaxie-copernicus/GalaxieCopernicus-Medium.woff2",
            weight: "400",
            style: "normal",
        },
        {
            path: "../../public/fonts/galaxie-copernicus/GalaxieCopernicus-Medium.woff2",
            weight: "500",
            style: "normal",
        },
    ],
});

const copernicusGalaxyBook = localFont({
    src: [
        {
            path: "../../public/fonts/galaxie-copernicus/GalaxieCopernicus-Book.woff2",
            weight: "400",
            style: "normal",
        },
        {
            path: "../../public/fonts/galaxie-copernicus/GalaxieCopernicus-Book.woff2",
            weight: "500",
            style: "normal",
        },
    ],
});

// eslint-disable-next-line @typescript-eslint/naming-convention
const EDNimpkishRegular = localFont({
    src: [
        {
            path: "../../public/fonts/ed-nimpkish/ed-nimpkish-regular.woff2",
            weight: "400",
            style: "normal",
        },
    ],
});

const GillSansMedium = localFont({
    src: [
        {
            path: "../../public/fonts/gill-sans/gill-sans-medium.woff2",
            weight: "400",
            style: "normal",
        },
    ],
});

const GillSansMediumItalic = localFont({
    src: [
        {
            path: "../../public/fonts/gill-sans/gill-sans-medium-italic.woff2",
            weight: "400",
            style: "normal",
        },
    ],
});

const GillSansBold = localFont({
    src: [
        {
            path: "../../public/fonts/gill-sans/gill-sans-bold.woff2",
            weight: "700",
            style: "normal",
        },
    ],
});

export const fonts: Fonts = {
    heading: EDNimpkishRegular.style.fontFamily,
    // eslint-disable-next-line @typescript-eslint/quotes
    body: "'GalaxieCopernicus-Medium', sans-serif",
    tag: EDNimpkishRegular.style.fontFamily,
    button: EDNimpkishRegular.style.fontFamily,
    copernicusGalaxyMedium: copernicusGalaxyMedium.style.fontFamily,
    copernicusGalaxyBook: copernicusGalaxyBook.style.fontFamily,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    eDNimpkishRegular: EDNimpkishRegular.style.fontFamily,
    GillSansMedium: GillSansMedium.style.fontFamily,
    GillSansMediumItalic: GillSansMediumItalic.style.fontFamily,
    GillSansBold: GillSansBold.style.fontFamily,
};

export {
    copernicusGalaxyMedium,
    copernicusGalaxyBook,
    EDNimpkishRegular,
    GillSansMedium,
    GillSansMediumItalic,
    GillSansBold
};
