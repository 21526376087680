/** @format */

export const fontSizes = {
	xs: '0.75rem',
	sm: '1rem',
	md: '1.25rem',
	lg: '1.5rem',
	xl: '2rem',
	'2xl': '2.5rem',
	'3xl': '3rem',
	'4xl': '3.5rem',
	'5xl': '4rem',
	'6xl': '4.5rem',
	'7xl': '6rem',
};
