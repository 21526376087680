/** @format */

import {accordionAnatomy as parts} from '@chakra-ui/anatomy';
import {
	createMultiStyleConfigHelpers,
	defineStyle,
} from '@chakra-ui/styled-system';
import {fonts} from './fonts';
import {textStyles} from './textStyles';

const {definePartsStyle, defineMultiStyleConfig} =
	createMultiStyleConfigHelpers(parts.keys);

const titleStylesSelected = {
	base: textStyles.headingsm,
	md: textStyles.headingmd,
	lg: textStyles.textlg,
};
const titleComputedFont = {
	fontSize: {
		base: titleStylesSelected.base.fontSize,
		md: titleStylesSelected.md.fontSize,
		lg: titleStylesSelected.lg.fontSize,
	},
	fontFamily: {
		base: titleStylesSelected.base.fontFamily,
		md: titleStylesSelected.md.fontFamily,
		lg: titleStylesSelected.lg.fontFamily,
	},
	fontWeight: {
		base: titleStylesSelected.base.fontWeight,
		md: titleStylesSelected.md.fontWeight,
		lg: titleStylesSelected.lg.fontWeight,
	},
	fontStyle: {
		base: titleStylesSelected.base.fontStyle,
		md: titleStylesSelected.md.fontStyle,
		lg: titleStylesSelected.lg.fontStyle,
	},
	lineHeight: {
		base: titleStylesSelected.base.lineHeight,
		md: titleStylesSelected.md.lineHeight,
		lg: titleStylesSelected.lg.lineHeight,
	},
	letterSpacing: {
		base: titleStylesSelected.base.letterSpacing,
		md: titleStylesSelected.md.letterSpacing,
		lg: titleStylesSelected.lg.letterSpacing,
	},
};

const bodyStylesSelected = {
	base: textStyles.textsm,
};
const bodyComputedFont = {
	fontSize: {
		base: bodyStylesSelected.base.fontSize,
	},
	fontFamily: {
		base: bodyStylesSelected.base.fontFamily,
	},
	fontWeight: {
		base: bodyStylesSelected.base.fontWeight,
	},
	fontStyle: {
		base: bodyStylesSelected.base.fontStyle,
	},
	lineHeight: {
		base: bodyStylesSelected.base.lineHeight,
	},
	letterSpacing: {
		base: bodyStylesSelected.base.letterSpacing,
	},
};

const baseStyleButton = defineStyle({
	transitionProperty: 'common',
	transitionDuration: 'normal',
	...titleComputedFont,
	_focusVisible: {
		boxShadow: 'outline',
	},
	_hover: {
		bg: 'blackAlpha.50',
	},
	_disabled: {
		opacity: 0.4,
		cursor: 'not-allowed',
	},
	px: '0',
	py: {base: '0.75rem', lg: '1.5rem'},
});

const baseStyleContainer = defineStyle({
	borderTopWidth: '1px',
	borderColor: 'inherit',
	_last: {
		borderBottomWidth: '1px',
	},
	p: '0',
	...bodyComputedFont,
});

const baseStylePanel = defineStyle({
	borderTopWidth: '1px',
	py: {
		base: '1.5rem',
		md: '2rem',
		lg: '3.5rem',
	},
	px: {
		base: '0',
	},
});

const baseStyleIcon = defineStyle({
	fontSize: {base: '1.5em', lg: '2.5em'},
	width: {base: '24px', lg: '40px'},
	height: {base: 'auto'},
});

const baseStyle = definePartsStyle({
	container: baseStyleContainer,
	button: baseStyleButton,
	panel: baseStylePanel,
	icon: baseStyleIcon,
});

export const Accordion = defineMultiStyleConfig({baseStyle});
